/* eslint-disable react/destructuring-assignment */

import React, { useContext } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { notification } from 'antd';
import i18next from 'i18next';

const LOCALSTORAGE_TOKEN_KEY = process.env.REACT_APP_LOCALSTORAGE_KEY;

const WebSocketContext = React.createContext(null);

export class WebSocketProvider extends React.Component {
  constructor(props) {
    super(props);

    this.ws = null;
    this.state = { ready: false, connected: null };
  }

  componentWillUnmount() {
    if (this.ws) {
      this.ws.removeEventListener('open', this.onConnect);
      this.ws.removeEventListener('close', this.onDisconnect);
    }
  }

  onConnect = () => {
    if (this.state.connected !== null) {
      notification.success({ message: i18next.t('connected-with-server') });
    }
    this.setState(st => ({ ...st, connected: true }));
  };

  onDisconnect = () => {
    if (this.state.connected) {
      notification.error({
        message: i18next.t('connection-interrupted'),
        description: i18next.t('connection-reset-trying')
      });
    }
    this.setState(st => ({ ...st, connected: false }));
  };

  createWS = () => {
    if (!this.state.ready || !this.ws) {
      const token = window.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      this.ws = new ReconnectingWebSocket(
        `${process.env.REACT_APP_WS_URL}/ws/`,
        token
      );
      this.ws.addEventListener('open', this.onConnect);
      this.ws.addEventListener('close', this.onDisconnect);

      this.setState(st => ({ ...st, ready: true }));
    }
    return this.ws;
  };

  render() {
    return (
      <WebSocketContext.Provider
        value={{ ws: this.ws, init: this.createWS, ...this.state }}
        {...this.props}
      />
    );
  }
}

export function useWebSocket() {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw Error('useWebSocket not have context');
  }
  return context;
}

export const withWebSocket = Comp => props => (
  <WebSocketContext.Consumer>
    {ws => <Comp {...props} webSocket={ws} />}
  </WebSocketContext.Consumer>
);
