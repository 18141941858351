import { lazy } from 'react';

function retry(fn, retriesLeft = 2, interval = 500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            window.location.reload(true);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function lazyLoad(importModuleName) {
  return lazy(() => retry(() => importModuleName()));
}
