import React, {useEffect, useMemo, useReducer} from "react";
import { useAsync } from 'react-async';
import { eventApi } from "@project/api";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {withFullDay} from "./day";
import {SpinnerBlock} from "../components";
import {Card, Result} from "antd";
import {useWebSocket} from "./ws";

class Events extends React.Component {
  componentDidMount() {
    const {
      events,
    } = this.props;

    document.addEventListener('EVENT:CHANGE', this.handleChangeEvent)
    document.addEventListener('EVENT:ADD', this.handleAddEvent)
    document.addEventListener('EVENT:REMOVE', this.handleRemoveEvent)
    document.addEventListener('MEETING:CHANGE', this.handleChangeMeeting)
    document.addEventListener('MEETING:ADD', this.handleAddMeeting)
    document.addEventListener('MEETING:REMOVE', this.handleRemoveMeeting)
    document.addEventListener('PERMISSION:CHANGE', this.handleChangeSpace)
    document.addEventListener('PERMISSION:ADD', this.handleAddSpace)
    document.addEventListener('PERMISSION:REMOVE', this.handleRemoveSpace)
  }
  componentWillUnmount() {
    document.removeEventListener('EVENT:CHANGE', this.handleChangeEvent)
    document.removeEventListener('EVENT:ADD', this.handleAddEvent)
    document.removeEventListener('EVENT:REMOVE', this.handleRemoveEvent)
    document.removeEventListener('MEETING:CHANGE', this.handleChangeMeeting)
    document.removeEventListener('MEETING:ADD', this.handleAddMeeting)
    document.removeEventListener('MEETING:REMOVE', this.handleRemoveMeeting)
    document.removeEventListener('PERMISSION:CHANGE', this.handleChangeSpace)
    document.removeEventListener('PERMISSION:ADD', this.handleAddSpace)
    document.removeEventListener('PERMISSION:REMOVE', this.handleRemoveSpace)
  }

  handleChangeEvent = (e) => {
    this.props.actions.changeEvent({ event: e.detail.slug, data: e.detail })
  }

  handleAddEvent = (e) => {
    this.props.actions.addEvent(e.detail)
  }

  handleRemoveEvent = (e) => {
    this.props.actions.removeEvent(e.detail.slug)
  }

  handleChangeMeeting = (e) => {
    this.props.actions.changeMeeting({ meeting: e.detail.name, data: e.detail })
  }

  handleAddMeeting = (e) => {
    this.props.actions.addMeeting(e.detail)
  }

  handleRemoveMeeting = (e) => {
    this.props.actions.removeMeeting(e.detail.name)
  }

  handleChangeSpace = (e) => {
    this.props.actions.changeSpace({ space: e.detail.space.id, data: e.detail })
  }

  handleAddSpace = (e) => {
    this.props.actions.addSpace(e.detail)
  }

  handleRemoveSpace = (e) => {
    this.props.actions.removeSpace(e.detail.space.id)
  }

  render() {
    return null;
  }
}

export const EventsContainer = withFullDay(Events);

const EventContext = React.createContext();

const initialState = {
  event: null,
  currentBlock: null,
  isLoading: true,
};

const actions = {
  setEvent: 'SET_EVENT',
  setCurrentBlock: 'SET_CURRENT_BLOCK',
  setEndLoading: 'SET_END_LOADING',
  endCurrentBlock: 'END_CURRENT_BLOCK',
};

function eventReducer(state = initialState, action) {
  switch (action.type) {

    case actions.setEvent:
      return {
        ...state,
        event: action.payload,
        isLoading: false,
      }

    case actions.setCurrentBlock:
      // prevent setting current block of a different event
      if (action.payload && action.payload.event_track === state.event.slug) {
        return {
          ...state,
          currentBlock: action.payload
        }
      }
      break;
    case actions.endCurrentBlock:
      if (action.payload && action.payload.event_track === state.event.slug) {
        return {
          ...state,
          currentBlock: null,
        }
      }
      break;
    case actions.setEndLoading:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state;
  }
  return state;
}

async function bootstrap({ eventId }) {
  const event = await eventApi.getEvent({ eventId });
  let current = null;
  try {
    current = await eventApi.getCurrentEventBlock({eventId});
  } catch (e) {
    // ignore if is not found...
  }
  return {
    event,
    current,
  };
}

export function EventProvider(props) {
  const { eventId } = useParams();
  const [state, dispatch] = useReducer(eventReducer, initialState);
  const { ws } = useWebSocket();
  const { t } = useTranslation();

  const bindedActions = useMemo(
    () =>
      Object.keys(actions).reduce(
        (p, actionName) => ({
          ...p,
          [actionName]: payload =>
            dispatch({ type: actions[actionName], payload })
        }),
        {}
      ),
    [dispatch]
  );

  const { error, isPending } = useAsync({
    promiseFn: bootstrap,
    eventId,
    onResolve: ({ event, current }) => {
      console.log(event);
      bindedActions.setEvent(event);
      bindedActions.setCurrentBlock(current);

      if (event) {
        ws.send(JSON.stringify({
          event: 'REGISTER',
          namespace: 'event',
          detail: event.slug
        }))
      }
    },
    onReject: () => {
      bindedActions.setEndLoading()
    }
  })

  useEffect(() => {
    function register() {
      if (state.event) {
        ws.send(JSON.stringify({
          event: 'REGISTER',
          namespace: 'event',
          detail: state.event.slug,
        }))
      }
    }
    document.addEventListener('WS:OPEN', register);
    return () => {
      document.removeEventListener('WS:OPEN', register);
    }

  }, [state.event, ws])

  useEffect(() => {
    function change(e) {
      if (e.detail.slug === state.event.slug) {
        bindedActions.setEvent(e.detail);
      }
    }
    document.addEventListener('EVENT:CHANGE', change);
    return () => document.removeEventListener('EVENT:CHANGE', change);
  }, [state.event, bindedActions])

  const value = useMemo(
    () => ({
      currentBlock: state.currentBlock,
      event: state.event,
      actions: bindedActions,
    }),
    [state, bindedActions]
  );

  if (isPending || state.isLoading) {
    return <SpinnerBlock />
  }

  if (error) {
    return (
      <Card>
        <Result status="error" title={t('event-permission-denied')} />
      </Card>
    );
  }

  return <EventContext.Provider {...props} value={value} />;
}


export function useCurrentBlock() {
  const context = React.useContext(EventContext);
  if (context === undefined) {
    throw new Error(`useCurrentBlock must be used within a DayProvider`);
  }
  return context.currentBlock;
}

export function useCurrentEvent() {
  const context = React.useContext(EventContext);
  if (context === undefined) {
    throw new Error(`useCurrentBlock must be used within a DayProvider`);
  }
  return context.event;
}

export function useCurrentEventActions() {
  const context = React.useContext(EventContext);
  if (context === undefined) {
    throw new Error(`useCurrentBlock must be used within a DayProvider`);
  }
  return context.actions;
}
