import React, { useState } from 'react';
import { Spin, Tooltip } from 'antd';
import { useAsync } from 'react-async';
import { userApi } from '@project/api';

export function AsyncTooltip({ slug, children }) {
  const [data, setData] = useState(null);
  const { run, isPending } = useAsync({
    deferFn: userApi.getUserData,
    slug,
    onResolve: d => {
      setData(d);
    }
  });

  return (
    <Tooltip
      onVisibleChange={() => {
        if (!data) {
          run();
        }
      }}
      placement="right"
      title={
        isPending || !data ? (
          <Spin />
        ) : (
          <>
            <strong>{data.name}</strong>
            <br />
            {data.job_title}
          </>
        )
      }
    >
      {children}
    </Tooltip>
  );
}
