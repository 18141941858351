import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { SpinnerPage } from '@project/components';
import {quizApi} from "@project/api";

const { getQuestions } = quizApi;

const QuizContext = React.createContext();

const ASYNC = {
  promiseFn: getQuestions
};

function QuizProvider(props) {
  const { t } = useTranslation();
  const [quizzes, setQuizzes] = useState(0);
  const { data, error, isRejected, isPending, isResolved, reload } = useAsync(
    ASYNC
  );

  useEffect(() => {
    if (data && data.length > quizzes) {
      setQuizzes(data.length);
      notification.success({
        message: t('new-quiz'),
        duration: 0, // disable auto-close
        onClick: () => {
          const e = new CustomEvent('QUIZ:NEW');
          document.dispatchEvent(e);
        },
        onClose: () => {
          const e = new CustomEvent('QUIZ:NEW');
          document.dispatchEvent(e);
        }
      });
    } else if (data && data.length < quizzes) {
      setQuizzes(data.length);
    }
  }, [data, quizzes, setQuizzes, t]);

  useEffect(() => {
    document.addEventListener('QUIZ:UPDATE', reload);
    return () => document.removeEventListener('QUIZ:UPDATE', reload);
  }, [reload]);

  if (isPending && !data) {
    return <SpinnerPage />;
  }

  return (
    <QuizContext.Provider
      value={{ data, reload, isPending, isResolved, isRejected, error }}
      {...props}
    />
  );
}

function useQuiz() {
  const context = React.useContext(QuizContext);
  if (context === undefined) {
    throw new Error(`useQuiz must be used within a QuizProvider`);
  }
  return context;
}

export { QuizProvider, useQuiz };
