import i18next from 'i18next';
import client from './client';

// eslint-disable-next-line import/prefer-default-export
export async function createMessage([data]) {
  await client('api/messages/', { body: data });
}

export async function getMessages({ url }) {
  const res = await client(
    url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : 'api/messages/'
  );
  return res.data;
}

export async function forwardMessage([id]) {
  const res = await client(`api/messages/${id}/forward/`, { method: 'POST' });
  if (!res.data.success) {
    throw new Error(i18next.t('send-email-failed'));
  }
}

export async function destroyMessage([id]) {
  await client(`api/messages/${id}/`, { method: 'DELETE' });
}

export async function getChats() {
  return client('api/chats/');
}
