import React from 'react';
import { useAsync } from 'react-async';
import { Button, Form, Input, notification, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { chatApi, meetingsApi } from '@project/api';
import { useParams } from 'react-router-dom';

const { getSpeakers } = meetingsApi;
const { createMessage } = chatApi;

export default function DirectQuestionMeeting({ close }) {
  const {meetingId} = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { data: speakers } = useAsync({
    promiseFn: getSpeakers,
    meeting: meetingId,
  });

  const { run } = useAsync({
    deferFn: createMessage,
    onResolve: () => {
      close();
    },
    onReject: () => {
      notification.error({ message: t('error-message-create') });
    }
  });

  return (
    <>
      <Form layout="vertical" form={form} onFinish={run}>
        <Form.Item
          name="receiver"
          label={t('speaker')}
          rules={[{ required: true }]}
        >
          <Select>
            {speakers &&
              speakers.map(s => (
                <Select.Option key={s.pk}>{s.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="text"
          label={t('message')}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Button htmlType="submit">{t('submit')}</Button>
      </Form>
    </>
  );
}
