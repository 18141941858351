import { Card, Col, Row } from 'antd';
import React from 'react';

export function CardBlock({ icon, text, ...props }) {
  return (
    <Card {...props}>
      <Row
        gutter={16}
        type="flex"
        justify="space-around"
        align="middle"
        className="fullHeight"
      >
        <Col span={8} className="icon-col">
          {icon}
        </Col>
        <Col span={16}>{text}</Col>
      </Row>
    </Card>
  );
}
