import React, { useCallback } from 'react';
import { Avatar, Button } from 'antd';
import moment from 'moment';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { useAsync } from 'react-async';

import * as classnames from 'classnames';
import './message.css';
import { useTranslation } from 'react-i18next';
import {GenericMessageList, useWebSocket, useUser} from "@project/components";
import {conferenceApi} from "@project/api";

const { deleteCoffeMessage } = conferenceApi;

function ChatMessage({
  created_at: date,
  user_name: name,
  user,
  text,
  slug,
  uid,
  isAdmin,
  destroy,
  id
}) {
  return (
    <div
      id={`message-${slug}`}
      className={classnames('chat_message__wrapper', { owner: uid === user })}
    >
      <div className="chat_message__header">
        <span>
          <Avatar
            src={`${process.env.REACT_APP_AVATAR_URL}${user}`}
            size={24}
          />
          <span style={{ marginLeft: '.5rem' }}>{name}</span>
        </span>
        <span>
          <span>{moment(date).format('H:mm')}</span>
          {isAdmin && (
            <Button
              size="small"
              style={{ marginLeft: '.3rem' }}
              onClick={() => destroy(id)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </span>
      </div>
      <div className="chat_message__text">{text}</div>
    </div>
  );
}

export default function Messages(props) {
  const webSocket = useWebSocket();
  const translation = useTranslation();
  const { uid, role } = useUser();
  const { run } = useAsync({
    deferFn: deleteCoffeMessage
  });

  const render = useCallback(
    i => (
      <ChatMessage uid={uid} {...i} isAdmin={role === 'admin'} destroy={run} />
    ),
    [uid, role, run]
  );

  return (
    <GenericMessageList
      {...props}
      webSocket={webSocket}
      {...translation}
      id="coffee-break-chat"
      getMessageListEvent="COFFEE_MESSAGE:GET_LIST"
      getOldMessagesEvent="COFFEE_MESSAGE:GET_OLD"
      newMessageEvent="COFFEE_MESSAGE:NEW"
      deleteMessageEvent="COFFEE_MESSAGE:DELETE"
      loadMessageEvent="COFFEE_MESSAGE:LOAD"
      loadOldMessageEvent="COFFEE_MESSAGE:LOAD_OLD"
      renderItem={render}
    />
  );
}
