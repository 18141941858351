
import client from './client';
import {notification} from "antd";

export async function getSpaces() {
    const response = await client('api/spaces/');

    if (response.error) {
        return [];
    }
    return response.data;
}

export async function addUserToGroup([data], { groupId }) {
    const payload = await client(`admin-api/spaces/useradd/`, { body: { email: data.email, space: groupId } });
    if (payload.error) {
        notification.error({
            message: "Errore nell'aggiungere l'utente al gruppo"
        });
        throw new Error("Errore nell'aggiungere l'utente al gruppo")
    }
}

export async function removeUserFromGroup([data], { onSuccess, groupId }) {
    const payload = await client(`admin-api/spaces/userremove/`, { body: { email: data.email, space: groupId } });
    if (payload.error) {
        notification.error({
            message: "Errore nel rimuovere l'utente al gruppo"
        });
        throw new Error("Errore nel rimuovere l'utente al gruppo")
    }
}


export async function getAdminSpaces() {
    const response = await client('admin-api/spaces/');

    if (response.error) {
        return [];
    }
    return response.data;
}

export async function createSpace([data]) {
    const response = await client('admin-api/spaces/', { body: data });

    if (response.error) {
        throw new Error(response.error);
    }
}

export async function deleteSpace([id]) {
    const response = await client(`admin-api/spaces/${id}/`, { method: 'DELETE' });

    if (response.error) {
        throw new Error(response.error);
    }
}

export async function getUsersForSpace({ space, url }) {
    const response = await client(url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : `admin-api/spaces-memberships/?space=${space}`);

    if (response.error) {
        return [];
    }
    return response.data;
}

export async function updateMembership([id, data]) {
    const response = await client(`admin-api/spaces-memberships/${id}/`, {
        method: 'PATCH',
        body: data,
    });

    if (response.error) {
        throw new Error(response.error);
    }
}