import React from 'react';
import { Avatar, Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useOnline, AsyncTooltip } from '@project/components';

export function OnlineList() {
  const onlines = useOnline();
  const { t } = useTranslation();

  return (
    <Card
      title={t('partecipants')}
      style={{ height: 'calc(100vh - 193px)' }}
      bodyStyle={{
        paddingTop: '1rem',
        paddingBottom: 0,
        overflowY: 'scroll',
        height: '55vh'
      }}
    >
      <Row gutter={[16, 16]}>
        {onlines.map(o => (
          <Col key={o}>
            <AsyncTooltip slug={o}>
              <Avatar
                size={32}
                src={`${process.env.REACT_APP_AVATAR_URL}${o}`}
              />
            </AsyncTooltip>
          </Col>
        ))}
      </Row>
    </Card>
  );
}
