import React, { useContext, useReducer, useMemo } from 'react';

const DayContext = React.createContext();

const actions = {
  setDay: 'SET_DAY',
  setCurrentBlock: 'SET_CURRENT_BLOCK',
  setSpaces: 'SET_SPACES',
  setMeetings: 'SET_MEETINGS',
  setEvents: 'SET_EVENTS',
  changeEvent: 'EVENTS/CHANGE',
  addEvent: 'EVENTS/ADD',
  removeEvent: 'EVENTS/REMOVE',
  changeMeeting: 'MEETINGS/CHANGE',
  addMeeting: 'MEETINGS/ADD',
  removeMeeting: 'MEETINGS/REMOVE',
  changeSpace: 'SPACE/CHANGE',
  addSpace: 'SPACE/ADD',
  removeSpace: 'SPACE/REMOVE',
};

const initialState = {
  day: null,
  currentBlock: null,
  spaces: [],
  events: [],
  meetings: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.setDay:
      if (action.payload !== state.day) {
        return {
          ...state,
          day: action.payload
        };
      }
      break;
    case actions.setCurrentBlock:
      if (action.payload !== state.currentBlock) {
        return {
          ...state,
          currentBlock: action.payload
        };
      }
      break;
    case actions.setSpaces:
      return {
        ...state,
        spaces: action.payload,
      }
    case actions.setMeetings:
      return {
        ...state,
        meetings: action.payload,
      }
    case actions.setEvents:
      return {
        ...state,
        events: action.payload,
      }
    case actions.addEvent:
      return {
        ...state,
        events: [...state.events, action.payload]
      }
    case actions.removeEvent:
      return {
        ...state,
        events: state.events.filter(e => e.slug !== action.payload)
      }
    case actions.changeEvent:
      return {
        ...state,
        events: state.events.map(e => {
          if (e.slug !== action.payload.event) {
            return e;
          }
          return action.payload.data;
        })
      }
    case actions.addMeeting:
      return {
        ...state,
        meetings: [...state.meetings, action.payload]
      }
    case actions.removeMeeting:
      return {
        ...state,
        meetings: state.meetings.filter(e => e.name !== action.payload)
      }
    case actions.changeMeeting:
      return {
        ...state,
        meetings: state.meetings.map(e => {
          if (e.slug !== action.payload.meeting) {
            return e;
          }
          return action.payload.data;
        })
      }
    case actions.addSpace:
      console.log(action.payload);
      return {
        ...state,
        spaces: [...state.spaces, action.payload]
      }
    case actions.removeSpace:
      return {
        ...state,
        spaces: state.spaces.filter(e => e.space.id !== action.payload)
      }
    case actions.changeSpace:
      return {
        ...state,
        spaces: state.spaces.map(e => {
          if (e.space.id !== action.payload.space) {
            return e;
          }
          return action.payload.data;
        })
      }
    default:
      return state;
  }
  return state;
}

export function DayProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const bindedActions = useMemo(
    () =>
      Object.keys(actions).reduce(
        (p, actionName) => ({
          ...p,
          [actionName]: payload =>
            dispatch({ type: actions[actionName], payload })
        }),
        {}
      ),
    [dispatch]
  );

  const value = useMemo(
    () => ({
      day: state.day,
      currentBlock: state.currentBlock,
      meetings: state.meetings,
      events: state.events,
      spaces: state.spaces,
      actions: bindedActions
    }),
    [state, bindedActions]
  );

  return <DayContext.Provider value={value} {...props} />;
}

DayProvider.whyDidYouRender = true;

export function useDay() {
  const context = useContext(DayContext);

  if (context === undefined) {
    throw Error('useDay not inside DayProvider');
  }

  return context;
}

export function useDayActions() {
  const context = useContext(DayContext);

  if (context === undefined) {
    throw Error('useDayActions not inside DayProvider');
  }

  return context.actions;
}

export function useSpaces() {
  const context = useContext(DayContext);

  if (context === undefined) {
    throw Error('useDaySpaces not inside DayProvider');
  }

  return context.spaces;
}

export function useMeetings() {
  const context = useContext(DayContext);

  if (context === undefined) {
    throw Error('useDaySpaces not inside DayProvider');
  }

  return context.meetings;
}

export function useEvents() {
  const context = useContext(DayContext);

  if (context === undefined) {
    throw Error('useDaySpaces not inside DayProvider');
  }

  return context.events;
}

export function useConference() {
  const context = React.useContext(DayContext);
  if (context === undefined) {
    throw new Error(`useCurrentBlock must be used within a DayProvider`);
  }
  return context.day;
}


export const withDay = Comp => props => (
  <DayContext.Consumer>
    {day => <Comp {...props} day={day} />}
  </DayContext.Consumer>
);

export const withFullDay = Comp => props => (
  <DayContext.Consumer>
    {day => <Comp {...props} {...day} />}
  </DayContext.Consumer>
);

