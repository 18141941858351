import { notification } from 'antd';
import i18next from 'i18next';
import client from './client';

export async function getQuestions() {
  const res = await client(`api/questions/`);
  if (!res.error) {
    return res.data;
  }
  throw Error(i18next.t('error-getting-questions'));
}

export async function sendAnswer([slug, data], { onSuccess }) {
  const res = await client(`api/user-answers/`, {
    body: { ...data, question: slug }
  });
  if (!res.error) {
    onSuccess();
    notification.success({ message: i18next.t('answer-saved') });
  } else {
    notification.error({ message: i18next.t('error-save-answer') });
  }
}

export async function getQuestionsAdmin() {
  const res = await client(`api/questions-admin/`);
  if (!res.error) {
    return res.data;
  }
  throw Error(i18next.t('error-getting-questions'));
}

export async function getAnswers({ id }) {
  const res = await client(`api/answers/`, { queryParams: { question: id } });
  if (!res.error) {
    return res.data;
  }
  throw Error(i18next.t('error-getting-questions'));
}

export async function deleteAnswer([id], { onSuccess }) {
  const response = await client(`api/answers/${id}/`, { method: 'DELETE' });
  if (!response.error) {
    onSuccess();
    notification.success({ message: 'Cancellato' });
  } else {
    notification.error({ message: 'Errore nel cancellare la risposta' });
  }
}

export async function deleteQuestion([id], { onSuccess }) {
  const response = await client(`api/questions-admin/${id}/`, {
    method: 'DELETE'
  });
  if (!response.error) {
    onSuccess();
    notification.success({ message: 'Cancellato' });
  } else {
    notification.error({ message: 'Errore nel cancellare la domanda' });
  }
}

export async function updateQuestion([id, data], { onSuccess }) {
  const response = await client(`api/questions-admin/${id}/`, {
    method: 'PATCH',
    body: data
  });
  if (!response.error) {
    onSuccess();
    notification.success({ message: 'Aggiornato' });
  } else {
    notification.error({ message: 'Errore nel modificare la domanda' });
  }
}

export async function updateAnswer([id, data], { onSuccess }) {
  const response = await client(`api/answers/${id}/`, {
    method: 'PATCH',
    body: data
  });
  if (!response.error) {
    onSuccess();
    notification.success({ message: 'Aggiornato' });
  } else {
    notification.error({ message: 'Errore nel modificare la risposta' });
  }
}

export async function createQuestion([data, clear], { onSuccess }) {
  const response = await client(`api/questions-admin/`, {
    method: 'POST',
    body: data
  });
  if (!response.error) {
    onSuccess();
    // eslint-disable-next-line no-unused-expressions
    clear && clear();
    notification.success({ message: 'Creato' });
  } else {
    notification.error({ message: 'Errore nel creare la domanda' });
  }
}

export async function createAnswer([data, clear], { onSuccess, question }) {
  const response = await client(`api/answers/`, {
    method: 'POST',
    body: { ...data, question }
  });
  if (!response.error) {
    onSuccess();
    // eslint-disable-next-line no-unused-expressions
    clear && clear();
    notification.success({ message: i18next.t('created') });
  } else {
    notification.error({ message: i18next.t('error-create-answer') });
  }
}

export async function getUserAnswers() {
  const res = await client(`api/questions/answers/`);
  if (!res.error) {
    return res.data;
  }
  throw Error(i18next.t('error-getting-questions'));
}