import React, { useEffect } from 'react';
import { useDay } from './day';
import {useCurrentEventActions} from "./events";

export function CurrentBlockContainer() {
  const actions = useCurrentEventActions();

  useEffect(() => {
    function handleBlockSet(e) {
      if (!e.detail || e.detail.error) {
        actions.setCurrentBlock(null);
      } else {
        actions.setCurrentBlock(e.detail);
      }
    }

    function handleBlockEnd(e) {
      actions.endCurrentBlock(e.detail);
    }

    document.addEventListener('CURRENT_BLOCK:SET', handleBlockSet);
    document.addEventListener('CURRENT_BLOCK:END', handleBlockEnd);

    return () => {
      document.removeEventListener('CURRENT_BLOCK:SET', handleBlockSet);
      document.removeEventListener('CURRENT_BLOCK:END', handleBlockEnd);
    };
  }, [actions]);

  return null;
}
