import { notification } from 'antd';
import client from './client';

export async function getMe() {
  const payload = await client('api/users/me/');
  if (!payload.error) {
    return payload.data;
  }
  throw payload.error;
}

export async function getUsers({ url }) {
  const payload = await client(
    url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : 'api/users/'
  );
  if (!payload.error) {
    return payload.data;
  }
  throw payload.error;
}

export async function updateUser([data], { onSuccess, uid }) {
  const payload = await client(`api/users/${uid}/`, {
    body: data,
    method: 'PATCH'
  });
  if (!payload.error) {
    onSuccess();
  } else {
    notification.error({
      message: "Errore nel modificare l'utente"
    });
  }
}

export async function createUser([data], { onSuccess }) {
  const payload = await client(`api/users/`, { body: data });
  if (!payload.error) {
    onSuccess();
  } else {
    notification.error({
      message: "Errore nel creare l'utente"
    });
  }
}

export async function getUserData(_data, { slug }) {
  const payload = await client(`api/users/${slug}/`);
  if (!payload.error) {
    return payload.data;
  }
  throw payload.error;
}
