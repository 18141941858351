import * as auth from './src/auth';
import * as chat from './src/chat';
import * as conference from './src/conference';
import * as quiz from './src/quiz';
import * as user from './src/user';
import * as workshop from './src/workshop';
import * as spaces from './src/spaces';
import * as meetings from './src/meetings';
import * as event from './src/event';
import * as videos from './src/videos';
import * as attachments from './src/attachments';
export { default } from './src/client';

export const authApi = auth;
export const chatApi = chat;
export const conferenceApi = conference;
export const quizApi = quiz;
export const userApi = user;
export const workshopApi = workshop;
export const spacesApi = spaces;
export const meetingsApi = meetings;
export const eventApi = event;
export const videoApi = videos;
export const attachmentsApi = attachments;
