import * as Sentry from '@sentry/browser';
import {userApi, spacesApi, meetingsApi} from "@project/api";
import {getEvents} from "@project/api/src/event";

const { getMe } = userApi;
const { getSpaces } = spacesApi;
const { getMeetings } = meetingsApi;
const LOCALSTORAGE_TOKEN_KEY = process.env.REACT_APP_LOCALSTORAGE_KEY;

export async function bootstrap({ actions }) {
  const { setSpaces, setMeetings, setEvents } = actions;
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
  if (!token) {
    return { user: null };
  }
  try {
    const user = await getMe();
    const spaces = await getSpaces();
    const meetings = await getMeetings();
    const events = await getEvents();

    setSpaces(spaces);
    setMeetings(meetings);
    setEvents(events);

    if (process.env.NODE_ENV === 'production') {
      Sentry.configureScope(scope => {
        scope.setUser(user);
      });
    }

    return { user };
  } catch (e) {
    return { user: null };
  }
}
