import React, {useEffect } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';


function DirectQuestionNotificationHandler() {
  const { t } = useTranslation();

  useEffect(() => {
    function notify() {
      notification.success({
        message: t('new-direct-question'), onClick: () => {
          const e = new CustomEvent('DIRECT_MESSAGE:CLICK');
          document.dispatchEvent(e);
        }
      });
    }
    document.addEventListener('DIRECT_MESSAGE:NEW', notify);
    return () => document.removeEventListener('DIRECT_MESSAGE:NEW', notify);
  }, [t]);

  return null;
}

export { DirectQuestionNotificationHandler };
