import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/it';

import HttpAPI from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpAPI)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    supportedLngs: ['en', 'it'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    saveMissing: process.env.NODE_ENV !== 'production',
    saveMissingTo: 'all',
    crossDomain: true,
    updateMissing: process.env.NODE_ENV !== 'production',
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/i18next/locales/{{lng}}/{{ns}}/`,
      addPath: `${process.env.REACT_APP_API_URL}/i18next/locales/{{lng}}/{{ns}}/`
    }
  });

i18n.on('languageChanged', function(lng) {
  console.log(lng);
  moment.locale(lng);
});

export default i18n;
