import React from 'react';
import moment from 'moment';
import Icon from '@ant-design/icons';
import {CoffeeIcon, ConferenceIcon, DigitalClock, WorkshopIcon} from "@project/illustrations";

export function displayHour(h, format) {
  const m = format ? moment(h, format) : moment(h);
  return m.format('H:mm');
}

export const blockEnum = {
  TRACK: 'ConferenceTrack',
  GENERIC: 'ConferenceGenericBlock',
  WORKSHOP: 'ConferenceWorkshop',
  COFFEE: 'ConferenceCoffeeBreak'
};

export const resourceNameTranslation = t => ({
  [blockEnum.TRACK]: t('conference'),
  [blockEnum.COFFEE]: t('coffee-break'),
  [blockEnum.GENERIC]: t('block'),
  [blockEnum.WORKSHOP]: t('workshop')
});

export const ResourceIcon = {
  [blockEnum.TRACK]: <Icon component={ConferenceIcon} />,
  [blockEnum.COFFEE]: <Icon component={CoffeeIcon} />,
  [blockEnum.GENERIC]: <Icon component={DigitalClock} />,
  [blockEnum.WORKSHOP]: <Icon component={WorkshopIcon} />
};

export function getSecondsFromStart(start) {
  const now = moment();
  const startTime = moment(start);
  return now.diff(startTime, 'seconds');
}
