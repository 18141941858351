import React from 'react';
import {toast, ToastContainer, Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Avatar } from 'antd';
import Icon from '@ant-design/icons';
import { throttle } from 'lodash';
import './clap.scss';

const STYLE = {
  position: 'absolute',
  marginLeft: '1.2rem',
  marginTop: '1rem'
};

export class ClapManager extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.newInteraction = throttle(this.newInteraction.bind(this), 700);
  }

  componentDidMount() {
    document.addEventListener(this.props.interactionEvent, this.newInteraction);
  }

  componentWillUnmount() {
    document.removeEventListener(this.props.interactionEvent, this.newInteraction);
  }

  // eslint-disable-next-line class-methods-use-this
  newInteraction(e) {
    toast(
      <>
        <Icon width={50} fill="white" component={this.props.clapComponent} />
        <Avatar
          size={30}
          src={`${process.env.REACT_APP_AVATAR_URL}${e.detail.user}`}
          style={STYLE}
        />
      </>
    );
  }

  render() {
    return (
        <ToastContainer
            position="bottom-left"
            autoClose={3500}
            closeButton={false}
            hideProgressBar
            newestOnTop={false}
            limit={10}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            transition={Zoom}
            toastClassName="toast-container"
            bodyClassName="toast-body"
        />
    );
  }
}
