import client from './client';
import {notification} from "antd";

export async function getEvents() {
    const response = await client('api/events/');

    if (response.error) {
        return [];
    }
    return response.data;
}

export async function getEvent({ eventId }) {
    const res = await client(`api/events/${eventId}/`)

    if (res.error) {
        throw Error(res.error);
    }
    return res.data;
}

export async function getCurrentEventBlock({ eventId }) {
    const res = await client(`api/events/${eventId}/current/`)

    if (res.error) {
        throw Error(res.error);
    }
    return res.data;
}

export async function getSpeakers({ event }) {
    const res = await client(`api/events/${event}/speakers/`);
    if (res.error) {
        return null;
    }

    return res.data;
}

export async function sendWord([data], { event, onSuccess }) {
    try {
        const res = await client('api/events-word-cloud/', {
            body: { ...data, event }
        });
        if (res.error) {
            notification.error({ message: 'Errore' });
        } else {
            onSuccess();
        }
    } catch (e) {
        notification.error({ message: 'Errore' });
    }
}

export async function getWordCloudDay({ event }) {
    const res = await client(`api/events/${event}/words/`);
    if (res.error) {
        return null;
    }

    return res.data;
}

export async function getAdminEvents({ url }) {
    const response = await client(
      url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : 'admin-api/events/'
    );

    if (response.error) {
        throw Error(response.error);
    }
    return response.data;
}

export async function createEvent([data, clear],) {
    const response = await client(`admin-api/events/`, {
        method: 'POST',
        body: data
    });
    if (!response.error) {
        clear && clear();
    } else {
        throw Error(response.error);
    }
}

export async function deleteEvent([id]) {
    const response = await client(`admin-api/events/${id}/`, {
        method: 'DELETE'
    });
    if (response.error) {
        throw Error(response.error);
    }
}


export async function updateEvent([id, data]) {
    const response = await client(`admin-api/events/${id}/`, {
        method: 'PATCH',
        body: data
    });
    if (response.error) {
        throw Error(response.error);
    }
}

export async function addSpeaker([data], { id }) {
    const response = await client(`admin-api/events/${id}/add-speaker/`, {
        method: 'POST',
        body: data
    });
    if (response.error) {
        throw Error(response.error);
    }
}

export async function removeSpeaker([userId, event]) {
    return updateEvent([event.slug, {
        speakers: event.speakers.filter(u => userId !== u)
    }])
}

export async function getAdminEventVideo({ event }) {
    const response = await client(`admin-api/event-videos/?event=${event}`);

    if (response.error) {
        throw Error(response.error);
    }
    return response.data;
}

export async function createBlock([data], { event }) {
    const response = await client(`admin-api/event-videos/`, {
        body: { ...data, event_track: event }
    });

    if (response.error) {
        throw Error(response.error);
    }
}

export async function editBlock([slug, data]) {
    const response = await client(`admin-api/event-videos/${slug}/`, {
        body: data,
        method: 'PATCH',
    });

    if (response.error) {
        throw Error(response.error);
    }
}

export async function deleteBlock([slug]) {
    const response = await client(`admin-api/event-videos/${slug}/`, {
        method: 'DELETE',
    });

    if (response.error) {
        throw Error(response.error);
    }
}

export async function deleteTrackMessage([id]) {
    const res = await client(`admin-api/event-messages/${id}/`, {
        method: 'DELETE'
    });
    if (res.error) {
        throw new Error(res.error);
    }
}
