import PropTypes from 'prop-types';
import React from 'react';
import { Card, Empty, List, Spin, Button, Tag } from 'antd';

import ArrowDownOutlined from '@ant-design/icons/lib/icons/ArrowDownOutlined';

export class GenericMessageList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            loading: true,
            loadingOld: false,
            allLoaded: false,
            lastItem: null,
            unreadMessages: 0,
            showGoDown: false
        };
    }

    componentDidMount() {
        const {
            webSocket,
            getMessageListEvent,
            loadMessageEvent,
            loadOldMessageEvent,
            newMessageEvent,
            deleteMessageEvent,
            eventDetailKey = 'data',
            eventDetail = null
        } = this.props;

        webSocket.ws.send(
            JSON.stringify({
                event: getMessageListEvent,
                [eventDetailKey]: eventDetail,
            })
        );
        document.addEventListener(newMessageEvent, this.onMessage);
        document.addEventListener(loadMessageEvent, this.onLoad);
        document.addEventListener(loadOldMessageEvent, this.onLoadOld);
        document.addEventListener(deleteMessageEvent, this.onDelete);
        this.messagesList.addEventListener('scroll', this.handleScroll, {
            capture: false,
            passive: true
        });
    }

    componentWillUnmount() {
        const {
            loadMessageEvent,
            loadOldMessageEvent,
            newMessageEvent,
            deleteMessageEvent
        } = this.props;

        document.removeEventListener(newMessageEvent, this.onMessage);
        document.removeEventListener(loadMessageEvent, this.onLoad);
        document.removeEventListener(loadOldMessageEvent, this.onLoadOld);
        document.removeEventListener(deleteMessageEvent, this.onDelete);
        this.messagesList.removeEventListener('wheel', this.handleScroll, {
            capture: false,
            passive: true
        });
    }

    addMessage = data => {
        const { userId } = this.props;
        this.setState(
            st => ({
                messages: [...st.messages, data]
            }),
            () => {
                if (
                    this.messagesList.scrollHeight >
                    2 * this.messagesList.clientHeight + this.messagesList.scrollTop
                ) {
                    this.goToBottom();
                } else if (data.owner === userId) {
                    this.goToBottom();
                } else {
                    this.setState(st => ({
                        ...st,
                        unreadMessages: st.unreadMessages + 1
                    }));
                }
            }
        );
    };

    onMessage = e => {
        this.addMessage(e.detail);
    };

    onLoad = e => {
        if (!e.detail.error) {
            this.setState(
                st => ({
                    ...st,
                    messages: e.detail,
                    loading: false
                }),
                this.goToBottom
            );
        }
    };

    onLoadOld = e => {
        const { lastItem } = this.state;
        if (!e.detail.error) {
            this.setState(
                st => ({
                    ...st,
                    messages: [...e.detail, ...st.messages],
                    loading: false,
                    loadingOld: false,
                    allLoaded: e.detail.length === 0
                }),
                () => {
                    if (lastItem) {
                        document.getElementById(`message-${lastItem}`).scrollIntoView(true);
                    }
                }
            );
        }
    };

    onDelete = e => {
        this.setState(st => ({
            ...st,
            messages: st.messages.filter(m => m.slug !== e.detail.slug)
        }));
    };

    handleScroll = () => {
        const { webSocket, getOldMessagesEvent, eventDetailKey = 'data', eventDetail = null } = this.props;
        const { allLoaded, loadingOld, messages } = this.state;
        if (!allLoaded && !loadingOld && this.messagesList.scrollTop === 0) {
            if (messages.length > 0) {
                webSocket.ws.send(
                    JSON.stringify({
                        event: getOldMessagesEvent,
                        message: messages[0].id,
                        [eventDetailKey]: eventDetail,
                    })
                );
                this.setState(st => ({
                    ...st,
                    loadingOld: true,
                    lastItem: messages[0].slug
                }));
            }
        }

        this.setState(st => ({
            ...st,
            unreadMessages:
                this.messagesList.scrollTop + this.messagesList.clientHeight ===
                this.messagesList.scrollHeight
                    ? 0
                    : st.unreadMessages,
            showGoDown:
                this.messagesList.scrollHeight >
                2 * this.messagesList.clientHeight + this.messagesList.scrollTop
        }));
    };

    goToBottom = () => {
        this.messagesList.scrollTo({
            top: this.messagesList.scrollHeight,
            behaviour: 'smooth'
        });
    };

    render() {
        const {
            messages,
            loading,
            loadingOld,
            showGoDown,
            unreadMessages
        } = this.state;

        const { t, renderItem, id, style, className } = this.props;

        return (
            <div
                ref={ref => {
                    this.messagesList = ref;
                }}
                className={className}
                style={style}
                id={id}
            >
                {showGoDown && (
                    <div style={{ position: 'fixed', right: '2rem', bottom: '90px' }}>
                        {unreadMessages > 0 && (
                            <Tag color="red">
                                {unreadMessages} {t('unread-messages')}
                            </Tag>
                        )}
                        <Button type="primary" shape="circle" onClick={this.goToBottom}>
                            <ArrowDownOutlined />
                        </Button>
                    </div>
                )}
                <div className="message-list-inner-wrapper">
                    {loadingOld && (
                        <div style={{ textAlign: 'center', margin: '1rem 2rem' }}>
                            <Spin />
                        </div>
                    )}
                    <List
                        dataSource={messages}
                        className="message-chat"
                        itemLayout="horizontal"
                        loading={loading}
                        locale={{
                            emptyText: (
                                <Card>
                                    <Empty
                                        description={t('no-message')}
                                        style={{ margin: 'auto' }}
                                    />
                                </Card>
                            )
                        }}
                        renderItem={renderItem}
                    />
                </div>
            </div>
        );
    }
}

GenericMessageList.propTypes = {
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    getMessageListEvent: PropTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    id: PropTypes.string,
    loadMessageEvent: PropTypes.string.isRequired,
    getOldMessagesEvent: PropTypes.string.isRequired,
    loadOldMessageEvent: PropTypes.string.isRequired,
    newMessageEvent: PropTypes.string.isRequired,
    renderItem: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    style: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    t: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    userId: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    webSocket: PropTypes.any
};
