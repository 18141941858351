import { notification } from 'antd';
import moment from 'moment';
import client from './client';

export async function getToday() {
  const res = await client('api/conference-days/today/');
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function getCurrentBlock() {
  const res = await client('api/conference-days/current/');
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function getDays() {
  const res = await client('api/conference-days/');
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function getDaysRange() {
  const res = await client('api/conference-days/after/');
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function getWordCloudDay({ day }) {
  const res = await client(`api/conference-days/${day}/words/`);
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function getSpeakers({ day }) {
  const res = await client(`api/conference-days/${day}/speakers/`);
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function getVideos() {
  const res = await client('api/playables/');
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function getBlocks({ day }) {
  const res = await client(`api/conference-blocks/?day=${day}`);
  if (res.error) {
    return null;
  }

  return res.data;
}

export async function createBlock([data, reset], { onSuccess, day }) {
  try {
    const res = await client('api/conference-blocks/', {
      body: {
        ...data,
        day,
        hour_start: moment(data.hour_start).format(),
        hour_end: moment(data.hour_end).format()
      }
    });
    if (res.error) {
      console.log(res);
      notification.error({ message: res.data[0] });
    } else {
      onSuccess();
      reset();
    }
  } catch (e) {
    notification.error({ message: 'Errore nella creazione del blocco evento' });
  }
}

export async function createSubBlock([data, reset], { onSuccess }) {
  try {
    const res = await client('api/conference-sub-blocks/', {
      body: {
        ...data,
        hour_start: moment(data.hour_start).format('HH:mm:ss')
      }
    });
    if (res.error) {
      console.log(res);
      notification.error({ message: res.data[0] });
    } else {
      onSuccess();
      reset();
    }
  } catch (e) {
    notification.error({
      message: 'Errore nella creazione del sotto blocco evento'
    });
  }
}

export async function deleteBlock([id], { onSuccess }) {
  const res = await client(`api/conference-blocks/${id}/`, {
    method: 'DELETE'
  });
  if (!res.error) {
    onSuccess();
  } else {
    notification.error({
      message: 'Errore nella cancellazione del blocco evento'
    });
  }
}

export async function editBlock([id, data], { onSuccess }) {
  const res = await client(`api/conference-blocks/${id}/`, {
    method: 'PATCH',
    body: data
  });
  if (!res.error) {
    onSuccess();
  } else {
    notification.error({
      message: 'Errore nella modifica del blocco evento'
    });
  }
}

export async function deleteSubBlock([id], { onSuccess }) {
  const res = await client(`api/conference-sub-blocks/${id}/`, {
    method: 'DELETE'
  });
  if (!res.error) {
    onSuccess();
  } else {
    notification.error({
      message: 'Errore nella cancellazione del blocco evento'
    });
  }
}

export async function createDate([data], { onSuccess }) {
  try {
    await client('api/conference-days/', { body: data });
    onSuccess();
  } catch (e) {
    notification.error({
      message: 'Errore nella creazione della giornata evento'
    });
  }
}

export async function updateDate([data], { id }) {
  await client(`api/conference-days/${id}/`, { body: data, method: 'PATCH' });
}

export async function createVideo([data], { onSuccess }) {
  try {
    const res = await client('api/playables/', { body: data });
    if (res.error) {
      notification.error({ message: 'Errore nella creazione del video' });
    } else {
      onSuccess();
    }
  } catch (e) {
    notification.error({ message: 'Errore nella creazione del video' });
  }
}

export async function createUpload(secured = false) {
  const params = secured ? { secured: true } : null;
  const res = await client('api/playables/direct/', {
    method: 'POST',
    queryParams: params
  });
  if (res.error) {
    throw new Error(res.error);
  }
  return res.data;
}

export async function sendMessage(body) {
  const res = await client('api/coffee-break-messages/', { body });
  return res.data;
}

export async function getMessages(conference) {
  const res = await client('api/coffee-break-messages/', {
    queryParams: { day: conference }
  });
  return res.data.results;
}

export async function getVisibleVideos({ url }) {
  const res = await client(
    url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : 'api/videos/'
  );
  return res.data;
}

export async function getVisibleVideo({ id }) {
  const res = await client(`api/videos/${id}/`);
  return res.data;
}

export async function sendWord([data], { day, onSuccess }) {
  try {
    const res = await client('api/word-cloud-entries/', {
      body: { ...data, day }
    });
    if (res.error) {
      notification.error({ message: 'Errore nella creazione del video' });
    } else {
      onSuccess();
    }
  } catch (e) {
    notification.error({ message: 'Errore nella creazione del video' });
  }
}

export async function deleteCoffeMessage([id]) {
  await client(`api/coffee-break-messages/${id}/`, {
    method: 'DELETE'
  });
}

export async function deleteTrackMessage([id]) {
  const res = await client(`api/conference-track-messages/${id}/`, {
    method: 'DELETE'
  });
  if (res.error) {
    throw new Error(res.error);
  }
}
