import i18next from 'i18next';
import client from './client';

const LOCALSTORAGE_TOKEN_KEY = process.env.REACT_APP_LOCALSTORAGE_KEY;

export async function login({ email, password }) {
  const res = await client('auth/login/', { body: { email, password } });
  /*  if (!res.error) {
    return res.data.ephemeral_token;
  }
  throw res.data.non_field_errors.join(' ');
}

export async function mfaLogin(data) {
  const res = await client('auth/login/code/', { body: data }); */
  if (!res.error) {
    window.localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, res.data.auth_token);
    return res.data.auth_token;
  }
  if (res.data.non_field_errors) {
    throw new Error(res.data.non_field_errors.join(' '));
  } else {
    throw new Error(i18next.t('error-server-communication'));
  }
}

export async function logout() {
  await client('auth/logout/', { method: 'POST' });
  window.localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
}

/* export async function register(data) {
  return client('auth/users/', { body: data, multipart: true });
} */

export async function askLoginEmail({ email }) {
  const res = await client('api/auth/login/email/', { body: { email } });
  if (!res.error) {
    return;
  }
  if (res.data.detail === 'Not found') {
    throw new Error(i18next.t('user-not-found'));
  } else if (res.data.non_field_errors) {
    throw new Error(res.data.non_field_errors.join(' '));
  } else {
    throw new Error(i18next.t('error-server-communication'));
  }
}

export async function loginWithToken({ email, otp }) {
  const res = await client('api/auth/login/token/', { body: { email, otp } });
  if (!res.error) {
    window.localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, res.data.auth_token);
    return res.data.auth_token;
  }
  if (res.data.status) {
    throw new Error(res.data.status);
  } else if (res.data.detail === 'Not found') {
    throw new Error(i18next.t('user-not-found'));
  } else if (res.data.non_field_errors) {
    throw new Error(res.data.non_field_errors.join(' '));
  } else {
    throw new Error(i18next.t('error-server-communication'));
  }
}

export async function askResetPassword({ email }) {
  let res;
  try {
    res = await client('api/auth/login/resetpassword/', { body: { email } });
  } catch (e) {
    throw new Error(i18next.t('error-server-communication'));
  }
  if (!res.error) {
    return;
  }
  console.log(res);
  if (res.error === 404 && res.data.detail.includes('Not found')) {
    throw new Error(i18next.t('user-not-found'));
  } else if (res.data.non_field_errors) {
    throw new Error(res.data.non_field_errors.join(' '));
  } else {
    throw new Error(i18next.t('error-server-communication'));
  }
}

export async function setPassword([ data ]) {
  let res;
  try {
    res = await client('auth/users/set_password/', { body: data });
  } catch (e) {
    throw new Error(i18next.t('error-server-communication'));
  }
  if (!res.error) {
    return;
  }
  console.log(res);
  if (res.error === 404 && res.data.detail.includes('Not found')) {
    throw new Error(i18next.t('user-not-found'));
  } else if (res.data.non_field_errors) {
    throw new Error(res.data.non_field_errors.join(' '));
  } else {
    throw new Error(i18next.t('error-server-communication'));
  }
}
