import React, { useMemo, useEffect } from 'react';
import { useAsync } from 'react-async';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import apiClient from '@project/api';
import { SpinnerPage } from '../components/';

const SettingsContext = React.createContext();

async function getSettings() {
  const res = await apiClient('api/settings/');
  if (!res.error) {
    return res.data;
  }
  throw new Error(res.error);
}

function SettingsProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const { t } = useTranslation();

  const asyncFn = useMemo(
    () => ({
      promiseFn: getSettings,
      onResolve: data => {
        setSettings(data);
      }
    }),
    []
  );

  useEffect(() => {
    function updateSettings(e) {
      setSettings(e.detail);
    }

    function forceRefresh() {
      notification.info({
        message: t('force-refresh'),
        description: t('force-refresh-detail')
      });

      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }

    document.addEventListener('SETTINGS:UPDATE', updateSettings);
    document.addEventListener('REFRESH:FORCE', forceRefresh);
    return () => {
      document.removeEventListener('SETTINGS:UPDATE', updateSettings);
      document.removeEventListener('REFRESH:FORCE', forceRefresh);
    };
  });

  const { error, isRejected, isPending, isSettled } = useAsync(asyncFn);

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <SpinnerPage />;
    }
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  return <SettingsContext.Provider value={settings} {...props} />;
}

SettingsContext.whyDidYouRender = true;

function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }
  return context;
}

export { SettingsProvider, useSettings };
