import React, { useEffect, useState, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { useWebSocket } from './ws';

const OnlineContext = React.createContext();

function OnlineProvider(props) {
  const [ready, setReady] = useState(false);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const { ws } = useWebSocket();

  const setConnected = useCallback(
    newList => {
      if (!isEqual(newList, connectedUsers)) {
        setConnectedUsers(newList);
      }
    },
    [connectedUsers]
  );

  useEffect(() => {
    if (ws && ready) {
      // Send a HEARTBEAT every 2 minutes
      const timer = setInterval(
        () => ws.send('{"event": "HEARTBEAT"}'),
        60 * 1000
      );
      ws.send('{"event": "PRESENCE:INIT"}');
      setTimeout(() => ws.send('{"event": "HEARTBEAT"}'), 1000);
      return () => clearInterval(timer);
    }
    return () => null;
  }, [ws, ready]);

  useEffect(() => {
    function onPresenceUpdate(e) {
      setConnected(e.detail);
    }

    document.addEventListener('PRESENCE:UPDATE', onPresenceUpdate);
    setReady(true);
    return () => {
      document.removeEventListener('PRESENCE:UPDATE', onPresenceUpdate);
    };
  }, [setConnected]);

  return <OnlineContext.Provider value={connectedUsers} {...props} />;
}

function useOnline() {
  const context = React.useContext(OnlineContext);
  if (context === undefined) {
    throw new Error(`useOnline must be used within a OnlineProvider`);
  }
  return context;
}

export { OnlineProvider, useOnline };
