export { default as UndrawCoffeeBreak } from './UndrawCoffeeBreak';
export { default as UndrawEvents } from './UndrawEvents';
export { default as UndrawSpeaker } from './UndrawConference';
export { default as UndrawConferenceCall } from './conferenceCall';


export { ReactComponent as CoffeeIcon } from './Coffee_Icon.svg';
export { ReactComponent as WorkshopIcon } from './Workshop_Icon.svg';
export { ReactComponent as ConferenceIcon } from './Teambuilding_Icon.svg';
export { ReactComponent as DigitalClock } from './Time_Icon.svg';

export { ReactComponent as QAIcon } from './Gamification_Icon.svg';
export { ReactComponent as ScheduleIcon } from './Schedule_Icon.svg';
export { ReactComponent as ProfileIcon } from './Profile_Icon.svg';
export { ReactComponent as WordcloudIcon } from './Wordcloud_Icon.svg';
export { ReactComponent as AdminIcon } from './Shield_Icon.svg';
export { ReactComponent as VideoIcon } from './VideoOld_Icon.svg';
export { ReactComponent as HomeIcon } from './Home_Icon.svg';
export { ReactComponent as Clap } from './Claps_Icon.svg';
export { ReactComponent as EmailIcon } from './Mail_Icon.svg';
