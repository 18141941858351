
import client from './client';
import {notification} from "antd";

export async function getMeetings() {
    const response = await client('api/meetings/');

    if (response.error) {
        return [];
    }
    return response.data;
}


export async function getAdminMeetings({ url }) {
    const response = await client(
      url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : 'admin-api/meetings/'
    );

    if (response.error) {
        throw Error(response.error);
    }
    return response.data;
}

export async function createMeeting([data, clear],) {
    const response = await client(`admin-api/meetings/`, {
        method: 'POST',
        body: data
    });
    if (!response.error) {
        clear && clear();
    } else {
        throw Error(response.error);
    }
}

export async function deleteMeeting([id]) {
    const response = await client(`admin-api/meetings/${id}/`, {
        method: 'DELETE'
    });
    if (response.error) {
        throw Error(response.error);
    }
}


export async function updateMeeting([id, data]) {
    const response = await client(`admin-api/meetings/${id}/`, {
        method: 'PATCH',
        body: data
    });
    if (response.error) {
        throw Error(response.error);
    }
}

export async function addSpeaker([data], { id }) {
    const response = await client(`admin-api/meetings/${id}/add-speaker/`, {
        method: 'POST',
        body: data
    });
    if (response.error) {
        throw Error(response.error);
    }
}

export async function removeSpeaker([userId, meeting]) {
    console.log(userId, meeting);
    return updateMeeting([meeting.name, {
        speakers: meeting.speakers.filter(u => userId !== u)
    }])
}

export async function getSpeakers({ meeting }) {
    const res = await client(`api/meetings/${meeting}/speakers/`);
    if (res.error) {
        throw Error(res.error);
    }

    return res.data;
}
