import client from "./client";
import {notification} from "antd";


export async function getVisibleFiles({ url }) {
  const res = await client(
    url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : 'api/attachments/'
  );
  return res.data;
}


export async function getAdminFiles({ url }) {
  const res = await client(
    url ? url.replace(`${process.env.REACT_APP_API_URL}/`, '') : 'admin-api/attachments/'
  );
  return res.data;
}

export async function deleteFile([id]) {
  const response = await client(`admin-api/attachments/${id}/`, { method: 'DELETE' });
  if (response.error) {
    throw Error(response.error);
  }
}

export async function updateFile([id, data]) {
  const response = await client(`admin-api/attachments/${id}/`, {
    method: 'PATCH',
    body: data
  });
  if (response.error) {
    throw Error(response.error);
  }
}