import React, { useEffect } from 'react';
import { notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useWebSocket } from './ws';
import { useUser } from "./auth";

function WSHandler() {
  const { init } = useWebSocket();
  const user = useUser();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (user) {
      const ws = init();

      // eslint-disable-next-line no-inner-declarations
      function handleMessage(e) {
        try {
          const wsEvent = JSON.parse(e.data);
          console.log(wsEvent);

          if (wsEvent.event === 'NOTIFICATION:NEW') {
            notification.info({
              message: wsEvent.data.title,
              description: wsEvent.data.text,
              icon: <InfoCircleOutlined style={{ color: 'blue' }} />,
              duration: 10 * 1000
            });
          } else {
            const customEvent = new CustomEvent(wsEvent.event, {
              detail: wsEvent.data
            });
            document.dispatchEvent(customEvent);
          }
        } catch (err) {
          console.error(err);
        }
      }

      function handleOpen(e) {
        const customEvent = new CustomEvent('WS:OPEN');
        document.dispatchEvent(customEvent);
      }

      ws.addEventListener('message', handleMessage);
      ws.addEventListener('open', handleOpen);

      return () => {
        ws.removeEventListener('message', handleMessage);
        ws.removeEventListener('open', handleOpen);
      };
    }
  }, [init, user]);

  return null;
}

export { WSHandler };
