import { notification } from 'antd';
import i18next from 'i18next';
import client from './client';

export async function createWorkshop([data, clear], { onSuccess }) {
  console.log('here');
  const response = await client(`api/workshop-groups/`, {
    method: 'POST',
    body: data
  });
  if (!response.error) {
    onSuccess();
    // eslint-disable-next-line no-unused-expressions
    clear && clear();
    notification.success({ message: 'Creato' });
  } else {
    notification.error({ message: 'Errore nel creare il workshop' });
  }
}

export async function updateWorkshop([id, data], { onSuccess }) {
  const response = await client(`api/workshop-groups/${id}/`, {
    method: 'PATCH',
    body: data
  });
  if (!response.error) {
    onSuccess();
    notification.success({ message: 'Aggiornato' });
  } else {
    notification.error({ message: 'Errore nel modificare il workshop' });
  }
}

export async function bulkInviteWorkshop([id, userList, reset], { onSuccess }) {
  const data = userList.split(',').map(u => ({ email: u.trim() }));
  const response = await client(`api/workshop-groups/${id}/bulk-invite/`, {
    method: 'POST',
    body: data
  });
  if (!response.error) {
    onSuccess();
    reset();
    notification.success({ message: 'Aggiornato' });
  } else {
    notification.error({ message: 'Errore nel modificare il workshop' });
  }
}

export async function removeUserWorkshop([id, data], { onSuccess }) {
  const response = await client(`api/workshop-groups/${id}/remove-user/`, {
    method: 'POST',
    body: { email: data }
  });
  if (!response.error) {
    onSuccess();
    notification.success({ message: 'Aggiornato' });
  } else {
    notification.error({ message: 'Errore nel modificare il workshop' });
  }
}

export async function deleteWorkshop([id], { onSuccess }) {
  const response = await client(`api/workshop-groups/${id}/`, {
    method: 'DELETE'
  });
  if (!response.error) {
    onSuccess();
    notification.success({ message: 'Cancellato' });
  } else {
    notification.error({ message: 'Errore nel cancellare il workshop' });
  }
}

export async function getWorkshops({ session }) {
  if (!session) {
    throw Error('no session specified');
  }
  const response = await client(`api/workshop-groups/me/`, {
    queryParams: { session }
  });
  if (!response.error) {
    return response.data;
  }
  notification.error({ message: i18next.t('error-getting-workshop') });
  throw Error('Error');
}

export async function getWorkshop({ id }) {
  const response = await client(`api/workshop-groups/${id}/`);
  if (!response.error) {
    return response.data;
  }
  notification.error({ message: 'Errore nello scaricare il workshop' });
  throw Error('Error');
}
