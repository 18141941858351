import * as Sentry from '@sentry/browser';

/**
 * configure sentry using current version number
 */
function configSentry() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn:
        'https://5e5366ceed834eea936cae39469a5945@o130102.ingest.sentry.io/5433757',
      whitelistUrls: [/https?:.*\.madwave\.app/],
      ignoreErrors: [
          'ResizeObserver loop limit exceeded',
          'Unexpected token \'<\'',
          'ResizeObserver loop completed with undelivered notifications.',
          'expected expression, got \'<\'',
          'NotFoundError: Failed to execute \'replaceChild\' on \'Node\': The node to be replaced is not a child of this node.'
      ]
    });
  }
}
export default configSentry();
