import React, { useMemo } from 'react';
import { useAsync } from 'react-async';
import { SpinnerPage } from '@project/components';
import { bootstrap } from '@project/libs';
import { useTranslation } from "react-i18next";

import { useDay } from './day';

const AuthContext = React.createContext();

const EMPTY_USER = { user: null };

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const { actions } = useDay();
  const { t } = useTranslation()

  const asyncFn = useMemo(
    () => ({
      promiseFn: bootstrap,
      actions,
    }),
    [actions]
  );

  const {
    data = EMPTY_USER,
    error,
    isRejected,
    isPending,
    isSettled,
    reload
  } = useAsync(asyncFn);

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  const value = useMemo(
    () => ({
      data,
      reload
    }),
    [data, reload]
  );

  if (!firstAttemptFinished) {
    if (isPending) {
      return <SpinnerPage />;
    }
    if (isRejected) {
      return (
        <div>
          <p>{t('there-was-an-error-reload')}</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  return <AuthContext.Provider value={value} {...props} />;
}

AuthContext.whyDidYouRender = true;

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useUser() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context.data.user;
}

function useRole() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useRole must be used within a RoleProvider`);
  }
  const { user } = context.data;
  const role = user && user.role ? user.role : null;
  return role;
}

export { AuthProvider, useAuth, useUser, useRole };
