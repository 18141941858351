import React from 'react';
import { Layout, Spin } from 'antd';

export function SpinnerPage() {
  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Content
        id="loading-page"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spin size="large" />
      </Layout.Content>
    </Layout>
  );
}
