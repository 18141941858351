import React from 'react';
import videojs from 'video.js';
import '@videojs/http-streaming';
import 'video.js/dist/video-js.css';

export class VideoPlayer extends React.Component {
  componentDidMount() {
    const { url } = this.props;
    this.player = videojs(this.videoNode, {
      autoplay: false,
      controls: true,
      fluid: true,
      sources: [
        {
          src: url,
          type: 'application/x-mpegURL'
        }
      ]
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div style={{ maxWidth: '45rem', margin: 'auto' }}>
        <div data-vjs-player>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={node => {
              this.videoNode = node;
            }}
            className="video-js"
          />
        </div>
      </div>
    );
  }
}
